import "./App.css";
import React from "react";
import web3 from './web3';
import lottery from './lottery';

class App extends React.Component {
  state = { 
    manager: '',
    players: [],
    balance: '',
    value: '0.01',
    message: '',
    winner: ''
  };

  async componentDidMount() {
    const manager = await lottery.methods.manager().call();
    const players = await lottery.methods.getPlayers().call();
    const balance = await web3.eth.getBalance(lottery.options.address);

    this.setState({ manager, players, balance });
  }

  onSubmit = async (event) => {
    event.preventDefault();

    const accounts = await web3.eth.getAccounts();

    this.setState({ message: 'Waiting on transaction success...'});

    await lottery.methods.enter().send({
      from: accounts[0],
      value: web3.utils.toWei(this.state.value, 'ether'),
      gas: '1000000',
      gasPrice: '5000000000'
    });

    this.setState({ message: 'You have been entered!'});
  };

  onClick = async () => {
    const accounts = await web3.eth.getAccounts();

    this.setState({ message: 'Waiting on transaction success...'});

    await lottery.methods.pickWinner().send({
      from: accounts[0]
    });

    const lastWinner = await lottery.methods.lastWinner().call();
    this.setState({lastWinner: lastWinner});

    this.setState({message: <text>A winner has been picked!<br />{this.state.lastWinner}</text>});
  };
  
  render() {
    return (
      <div style={{
        backgroundImage: "url(/logo128.png)",
        backgroundColor: '#666666',
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top right",
        width: 'full',
        height: 'full',
        padding: '20px 20px 30px 20px'
        }}>
        <h2>Tyrell-Yutani Ind.</h2>
        <h4>Associate Lottery Contract</h4>
        <p>This contract is managed by:</p>
        <p style={{color: '#222222',fontSize: 'small'}}>{this.state.manager}.</p>
        <p>
          There are currently {this.state.players.length} entrant(s)
          competing to win {web3.utils.fromWei(this.state.balance, 'ether')} ether!
        </p>

        <hr />

        <form onSubmit={this.onSubmit}>
          <h4>Want to try your luck?</h4>
          <div>
            <label>Amount of ether to enter: <br /></label>
            <input 
              value={this.state.value}
              onChange={event => this.setState({ value: event.target.value })}
            />
            <p style={{color: '#222222',fontSize: 'small'}}>(Minimum is 0.01)</p>
          </div>
          <h4><button>Enter</button></h4>
          
        </form>

        <hr />

        <h4>Ready to pick a winner?</h4>
        <p style={{color: '#222222',fontSize: 'small'}}>Must be manager account</p>
        <button onClick={this.onClick}>Pick a winner!</button>
        

        <hr />

        <p style={{color: '#222222',fontSize: 'small'}}><a href="https://rinkeby.etherscan.io/address/0x94B3D974C5477CAa530736eCeCa7841912876Ac5">View contract on etherscan</a></p>

        <h3 style={{color: '#442222'}}>{this.state.message}</h3>
      </div>
    );
  }
}
export default App;
